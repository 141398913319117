<template>
  <el-row :gutter="0">
    <el-col :span="6" :offset="1" class="logo">
      <img src="../assets/beizengLogo.jpg" alt="" />
    </el-col>
    <el-col
      :span="3"
      :offset="14"
      type="flex"
      justify="end"
      style="height:60px"
    >
      <!-- <el-row :gutter="0">
        <el-col
          :span="4"
          :offset="12"
          style="align-items: center; display: flex;"
        >
          <el-avatar
            disabled
            icon="el-icon-user-solid"
            size="medium"
            :src="userInfo.avatar || avatar"
          >
          </el-avatar>
        </el-col>
        <el-col :span="8">
          <el-menu
            class="el-menu-user"
            mode="horizontal"
            text-color="#000"
            @select="headerCommand"
            style="height:50px"
          >
            <el-submenu index="1">
              <template #title>{{ userInfo.name || "" }}</template>
              <el-menu-item v-if="!isHome" icon="el-icon-setting" index="home"
                >回到首页</el-menu-item
              >
              <el-menu-item
                v-if="isHome && showAdmin"
                icon="el-icon-setting"
                index="mg"
                >管理后台
              </el-menu-item>
              <el-menu-item icon="el-icon-switch-button" index="quit"
                >退出</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row> -->
      <el-dropdown @command="handleCommand">
        <div class="el-dropdown-link">
          <el-avatar
            icon="el-icon-user-solid"
            :src="userInfo.avatar"
          ></el-avatar>
          <p>{{userInfo.name}}</p>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="home" v-if="!isHome"
              >回到首页</el-dropdown-item
            >
            <el-dropdown-item command="admin" v-if="isHome && showAdmin"
              >管理后台</el-dropdown-item
            >
            <el-dropdown-item command="loginout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-col>
  </el-row>
</template>

<script>
import { getUserInfo, quit, checkUserRole, homePage } from "@/utils/tools";
import avatar from "@/assets/avatar.jpeg";
import router from "@/router/index.js";
import { computed, ref, reactive, defineComponent } from "vue";
import { role } from "@/utils/constant";
export default defineComponent({
  setup() {
    // 初始化数据
    let handleCommand = (command) => {
      console.log(command);
      if (command == "admin") {
        //toAdmin()
      } else if (command == "home") {
        // homePage();
        router.push("/home")
      } else if (command == "loginout") {
        //toLogin()
      }
    };
    // 是否是管理后台页面
    let userInfo = reactive(getUserInfo());
    // 是否是（前台）首页
    let isHome = computed(() => {
      let chref = router.currentRoute.value.path;
      return (
        chref &&
        (chref.startsWith("/pages/info") || chref.startsWith("pages/info"))
      );
    });
    // 是否需要显示管理后台
    let showAdmin = computed(() => {
      return checkUserRole(role.owner) || checkUserRole(role.abover);
    });

    // 头部链接跳转
    function headerCommand(key) {
      if (key === "home") {
        router.push("/pages/info");
      } else if (key === "mg") {
        router.push("/admin/abovers");
      } else if (key === "quit") {
        quit();
      }
    }
    return {
      isHome,
      showAdmin,
      userInfo,
      avatar,
      headerCommand,
      handleCommand,
    };
  },
});
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 160px;
    height: 37px;
  }
}
::v-deep(.el-dropdown) {
  height: 100%;
  line-height: 60px;
}
.el-dropdown-link {
  height: 100%;
  cursor: pointer;
  color: #409eff;
  display: flex;
  align-items: center;
  p {
    font-size: 18px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #888c92;
    margin-left: 20px;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
