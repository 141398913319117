<template>
    <el-container style="height:100%;">
        <!-- <Auth /> -->
        <el-header style="background-color:rgb(118 130 138);">
            <Header></Header>
        </el-header>
        <el-container style="background-color:rgb(245,245,245)">
            <router-view />
        </el-container>
    </el-container>
</template>


<script>
    import Header from '@/components/Header.vue';
    // import Auth from '@/components/Auth.vue';
    export default {
        components: { Header },
    }
</script>